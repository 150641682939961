import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import debounce from "lodash.debounce";
import "./footer.sass";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      debounce(() => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight * 0.8
        ) {
          this.setState({
            items: this.state.items.concat(Array.from({ length: 20 })),
          });
        }
      }, 10)
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            friendnet: markdownRemark(
              frontmatter: { for: { eq: "friendnet" } }
            ) {
              frontmatter {
                content {
                  name
                  site
                }
              }
            }
            eeto: markdownRemark(frontmatter: { for: { eq: "eeto" } }) {
              frontmatter {
                content {
                  eeto
                  site
                }
              }
            }
          }
        `}
        render={(data) => {
          // const friends = data.friendnet.frontmatter.content;
          const eeto = data.eeto.frontmatter.content;

          return (
            <footer className="footer">
              <div className="content has-text-centered">
                <a
                  href="https://github.com/milotrince"
                  className="icon is-medium"
                >
                  <i className="fa fa-github" />
                </a>
                <a
                  href="https://twitter.com/milotrince"
                  className="icon is-medium"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/in/trinitychung/"
                  className="icon is-medium"
                >
                  <i className="fa fa-linkedin" />
                </a>
                <span className="icon is-medium a">
                  <i className="fa fa-envelope" />
                </span>
                <span>hi@trinityjchung</span>

                <div className="mx-auto p-4" style={{ maxWidth: "700px" }}>
                  {/* <h3>friendnet</h3>
                <p>
                  {friends.map(f => (
                    <span>
                      <a href={f.site}>{f.name}</a> ~{" "}
                    </span>
                  ))}
                  </p> */}
                  <p className="eeto">
                    {eeto.map((f) => (
                      <a href={f.site}>
                        <img src={"/img/8831/" + f.eeto}></img>
                      </a>
                    ))}
                  </p>
                </div>

                <p>site last updated: 2024/09/07</p>
              </div>

              <div>
                {this.state.items.map((i, index) => (
                  <span
                    key={index}
                    className="test"
                    style={{ opacity: `${Math.min(index / 2000, 1)}` }}
                  >
                    the end is never&nbsp;
                  </span>
                ))}
              </div>
            </footer>
          );
        }}
      />
    );
  }
}

export default Footer;
